import React from "react"
import Button from "../components/Button"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";

// const goToForm = () => {
// 	console.log("hola mundo")
// }

export default function about(props) {
    return (
        <>
            <div className="container mx-auto p-12 lg:px-0 grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-12">
                <div className="col-span-1">
                    <h1 className="text-5xl text-left font-bold text-gray">
                        Cambiamos la forma de trabajar
                    </h1>
                </div>
                <div className="col-span-1 flex flex-row justify-start items-center">
                    <span className="text-gray-400 text-base leading-loose">
                        En Pentcloud creamos productos digitales que permiten a las personas hacer las cosas de manera diferente. Comparte tu desafío con nuestro equipo y trabajaremos contigo para ofrecer un producto digital revolucionario.
                    </span>
                </div>
            </div>
            <div className="container mx-auto grid lg:grid-cols-3 grid-cols-1 gap-12 py-12">
                <div className="col-span-1 lg:col-span-2 w-full rounded-xl bg-gray-400 overflow-hidden">
                    {/* <img src="https://cdn01.prd.pentcloud.com/site/Nosotros/1.jpg" alt="" className="object-contain object-center" /> */}
                    <StaticImage src="https://cdn01.prd.pentcloud.com/site/Nosotros/1.jpg" className="object-contain object-center w-full" quality="100" alt="Learning"/>
                </div>
                <div className="col-span-1 flex flex-col">
                    <div className="w-full h-1/2 rounded-xl bg-gray-400 mb-12 overflow-hidden">
                        <img src="https://cdn01.prd.pentcloud.com/site/Nosotros/2.jpg" alt="" className="object-contain object-center" />
                    </div>
                    <div className="w-full h-1/2 rounded-xl bg-gray-400 overflow-hidden">
                        <img src="https://cdn01.prd.pentcloud.com/site/Nosotros/3.jpg" alt="" className="object-contain object-center" />
                    </div>
                </div>
            </div>
            <div className="px-12 py-12 lg:py-40 bg-white">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24">
                    <div className="col-span-1 px-12 lg:pr-24 hidden lg:block">
                        <div className="border h-full w-full rounded-xl bg-transparent border-green-900 relative">
                            <div className="w-full rounded-xl bg-gray-400 lg:absolute top-12 right-12 overflow-hidden">
                                <img src="https://cdn01.prd.pentcloud.com/site/Nosotros/4.jpg" alt="" className="object-contain object-center" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <h2 className="text-4xl font-bold text-gray mb-12 flex flex-col">
                            Nuestro objetivo
                        </h2>
                        <span className="lg:text-gray-400 text-gray text-base leading-loose">
                        Desde el principio, nuestro principal objetivo fue ayudar a las personas a crear nuevas soluciones digitales que importen y tengan un impacto en la vida diaria. Construir y enviar productos que sean útiles para las personas, haciendo del mundo un lugar mejor, para todos, incluidos nosotros mismos.
                        </span>
                    </div>
                </div>
            </div>
            <div className="px-12 py-12 lg:py-40 bg-white">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24">
                    <div className="col-span-1">
                        <h2 className="text-4xl font-bold text-gray mb-12">
                            Nuestros valores
                        </h2>
                        <div className="flex flex-col">
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Superar las expectativas de clientes. Nunca conformarnos con lo “suficientemente bueno” cuando sabemos que puede y debe hacerse mejor.
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Ser valientes, curiosos y experimentar: aprender de todos los éxitos y fracasos, así es como aprendemos y cómo innovamos.
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Ser ambiciosos y crecer junto a las personas que nos rodean. A medida que trabajamos para alcanzar nuestros objetivos profesionales, tenemos en cuenta a las personas que nos rodean y cómo podemos ayudarnos.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 px-12 lg:pl-24">
                        <div className="border h-full w-full rounded-xl bg-transparent border-green-900 relative">
                            <div className="w-full rounded-xl bg-gray-400 lg:absolute bottom-12 left-12 overflow-hidden">
                                <img src="https://cdn01.prd.pentcloud.com/site/Nosotros/5.jpg" alt="" className="object-contain object-center" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto flex flex-col lg:mt-40 mt-12 items-center justify-center rounded-lg bg-blue-900 shadow-xl p-12">
						<h2 className="text-white font-extrabold text-4xl mb-12">
							¿Te interesa mejorar la productividad de tu empresa?
						</h2>
			            <AniLink paintDrip hex="#FFFFFF" to="/#mainForm" activeClassName="font-bold text-blue">
                            <Button classes="px-8 py-4 bg-white font-bold text-green-900 rounded-lg">
                                Contáctanos ahora
                            </Button>
                        </AniLink>
				</div>
            </div>
        </>
    )
}